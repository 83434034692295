import * as React from "react"
import Layout from '../components/Layout'
import SEO from "../components/seo"
import {Registration} from '../components/Registration';
import {OurPlans} from '../components/OurPlans';

const PlanesPage = () => {

  return (
    <Layout clsOverflow={false} typeRouteMenu={'/#'}>
      <SEO
        title=""
        description=""
        works=''
        bolImage='1'
        ogImage={`home_f.jpg`}
        twitterImage={`home_t.jpg`}
        canonicalUrl="https://www.callmatik.com/planes"
      />


      <OurPlans inAllsLine={true} spaceTop={false} spaceBottom={true} link={'/metodopago'}/>

    </Layout>
  )
}

export default PlanesPage;
